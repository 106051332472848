body {
  background-color: #F9F2FA;
  margin: 0;
  padding: 0;
  font-family: Poppins;
  background-image: url('https://staging.chatsilo.com/assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

p {
  margin-bottom: 0;
}

/******************header***************/
.header {
  background: #ffffff;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.49);
  padding: 0;
}

.fa.fa-bars.navbar-toggler-icon {
  color: black;
}

.header .form-control {
  background: rgba(116, 123, 132, 0.08);
  border-radius: 7px;
  border: none;
  font-style: normal;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  line-height: 21px;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0 !important;
  margin-left: 0px;
  border-bottom-left-radius: 0 !important;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.header .form-control::placeholder {
  color: #572f5b;
}

.header .nav-link {
  cursor: pointer;
}

.header .input-group-text {
  background: rgba(116, 123, 132, 0.08);
  border-radius: 7px;
  height: 40px;
  border: none;
}

/*********************left side****************/
.left_side {
  background: #ffffff;
  padding-top: 4px;
}

.left_side .nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
}

.left_side .purple {
  display: none;
}

.left_side img {
  margin-right: 10px;
}

.left_side .active .white {
  display: none;
}

.left_side .active .purple {
  display: inline;
}

.left_side .navbar-nav {
  margin-top: 25px;
}

.left_side .foot {
  margin-top: 0;
}

/***************right side*********************/
.left_col h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  color: #000000;
}

.right_side {
  padding: 18px 25px;
}

.right_side.accounts_page {
  padding: 9px 25px;
}

.right_side .nav-tabs {
  border-bottom: none;
  background: #ffffff;
  margin-bottom: 25px;
  border-radius: 10px;
  padding: 8px 18px 0px;
}

.right_side .nav-tabs .nav-link.active {
  background-color: #fff;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 2px solid #572f5b;
  border-radius: 20px 20px 0px 0px;
  line-height: 21px;
  color: #333333;
}

.header .form-control:focus {
  color: #212529;
  background-color: rgba(116, 123, 132, 0.08);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: unset;
}

.input-group-text {
  cursor: pointer;
}

.right_side .form-control:focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: unset;
}

.right_side .columns {
  background: #ffffff;
  box-shadow: 0px 2.65437px 59.7232px rgba(163, 171, 185, 0.24);
  border-radius: 5px;
  padding: 22px 16px 10px;
  margin-bottom: 25px;
}

.right_side .columns img {
  margin-right: 5px;
}

.right_side .columns h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8fa0b9;
}

.right_side .columns h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #464646;
}

.right_side .nav-tabs .nav-link {
  margin-bottom: 0;
  background: 0 0;
  border: none;
  margin-right: 32px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.right_side .nav-link {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #c2c2c2;
}

.right_side .total_number {
  width: 70%;
}

.right_side .arrow {
  width: 30%;
}

.right_side .head_name {
  margin-bottom: 20px;
}

.right_side.accounts_page .head_name {
  margin-bottom: 10px;
}

.right_side .table_sec .table {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
}

.right_side .table> :not(:first-child) {
  border-top: none;
}

.right_side .table th {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  color: #333333;
}

.right_side .table td {
  color: #6c757d;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
}

.right_side .table thead {
  background: #f2f2f2;
  border-radius: 10px;
}

.right_side .pagination .page-link {
  background: #ffffff;
  border: 1px solid #bbbbbb;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4a4a4a;
}

.right_side .table .success {
  color: #66b718;
}

.right_side .table .fail {
  color: #fa5f1c;
}

.right_side .actions a {
  background: #af5e9d;
  border-radius: 5px;
  font-style: normal;
  text-decoration: none;
  padding: 10px 13px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.right_side .head_top a {
  background: #ffffff;
  border: 1px solid #f5ebf5;
  border-radius: 5px;
  display: block;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  text-decoration: none;
  color: #181818;
}

.right_side .head_top.search_field {
  width: 33%;
}

.right_side .actions a img {
  margin-right: 15px;
}

.right_side .head_top a img {
  margin-left: 8px;
}

.right_side .actions,
.right_side .head_top {
  margin-right: 16px;
}

.right_side .action_last_col {
  margin-right: 0;
}

.right_side .head_top .form-control {
  background: #ffffff;
  border-right: none !important;
  border: 1px solid #f5ebf5;
  border-radius: 5px;
}

.right_side .head_top .input-group-text {
  background: #ffffff;
  border: 1px solid #f5ebf5;
  border-left: none !important;
  border-radius: 5px;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.contacts_sec {
  background: #ffffff;
  padding-top: 28px;
  height: 670px;
  overflow-y: scroll;
}

.contacts_sec h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.right_side .add_tag,
.save_btn {
  background: linear-gradient(109.47deg,
      #572f5b 13.06%,
      #af5e9d 67.2%,
      #572f5b 101.91%) !important;
  border: 1px solid rgba(87, 47, 91, 0.47);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.right_side .head_top.upload a img {
  margin-left: 0;
}

.head_top .dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none;
}

.right_side .head_top .dropdown-item {
  background: transparent;
  border: none;
  padding: 7px 13px;
  border-radius: 5px;
  display: block;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #181818;
}

.head_top .dropdown-menu {
  background: #fafafa;
  box-shadow: 0px 0px 6px rgba(24, 24, 24, 0.12);
  border-radius: 5px;
  border: none;
}

.save_btn {
  display: inline !important;
  padding: 10px 30px !important;
}

.contact_name h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6c757d;
}

.contacts_list {
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  margin-top: 14px;
  padding: 5px 0px;
}

.tag_cols h2 {
  font-style: normal;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
  word-break: break-all;
  line-height: 20px;
  color: #000000;
}

.tags_sec .tag_icons {
  display: none;
}

.tag_cols:hover .tag_icons {
  display: flex;
  position: absolute;
  bottom: 0;
}

.tags_sec .tag_cols {
  padding: 13px 0px 0px;
  border-radius: 5px;
  min-height: 100px;
  padding-bottom: 13px;
  min-height: 50pxx !important;
  position: relative;
}



.tag_icons .action_icons.icon {
  border-radius: 0px 0px 0px 5px;
}

.tags_sec .tag_col_sec {
  padding-right: 0;
  margin-bottom: 20px;
}

.tag_icons .action_icons {
  border-radius: 0px 0px 0px 0px;
  padding: 2px 11px 2px 11px;
  margin: 0px 2px 0px 1px;
}

.tag_icons .action_icons.paint_icon {
  border-radius: 0px 0px 5px 0px;
  margin-right: 0;
}

.right_side p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #767476;
}

.account_profile .profile_cols {
  background: #ffffff;
  padding: 18px 20px;
  border-radius: 10px;
}

.account_profile .profile_cols .form-control {
  background: #ffffff;
  border: 1.44444px solid #f2eeee;
  border-left: none;
  border-radius: 10px;
  padding-left: 0;
}

.account_profile .profile_cols .input-group-text {
  background: #ffffff;
  border-right: none !important;
  border: 1.44444px solid #f2eeee;
  border-radius: 10px;
}

.profile_cols h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.team_Profile_cols h4 {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 9px;
  color: #333333;
}

.team_Profile_cols {
  background: #fcf8fd;
  border-radius: 5px;
  padding: 20px 0px;
  text-align: center;
}

.team_Profile_cols_sec {
  margin-top: 15px;
}

.tag_cols .form-control {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #181818;
}

.tag_cols .form-control:focus {
  background: #eaf4e0;
  border: none;
}

.account_page .tag_cols {
  padding: 9px;
  border-radius: 5px;
}

.right_side .account_page {
  padding-left: 29px;
  padding-right: 21px;
}

.bulk_message_cols p {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #181818;
  margin-bottom: 7px;
}

.left_col h3 img {
  margin-right: 12px;
}

.right_side .message_sec {
  margin-bottom: 15px;
}

.bulk_message_cols span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-right: 27px;
  color: #747b84;
  margin-left: 10px;
}

.target_friend .btn {
  background: #572f5b;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  padding: 7px 21px;
  color: #ffffff;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: unset;
}

.target_friend .dropdown-menu {
  border: none;
  min-width: 11rem;
}

.target_friend h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-left: 10px;
  color: #6c757d;
}

.user_sec {
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  padding: 5px 10px;
}

.target_friend .dropdown-item {
  padding: 0;
}

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: unset;
}

.template_cols {
  /* background-image: url(../images/template_banner.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 5px;
}

.templates_sec .head_top h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 21px;
  color: #181818;
  margin-left: 17px;
}

.templates_sec .head_top {
  margin-right: 0px;
  flex: 0.5;
}

.templates_sec .head_top.search_field {
  margin-right: 18px;
}

.templates_sec .head_top.search_field {
  width: 40%;
}

.template_message .form-control {
  background: #ffffff;
  border-radius: 7.05508px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  border: none;
  /* height: 140px; */
}

textarea {
  resize: none;
}

.template_message .form-control::placeholder {
  color: #181818;
}

.template_message a img {
  margin-right: 10px;
}

.template_message {
  margin-bottom: 20px;
}

.template_cols .head_top.search_field {
  width: 100%;
}

.template_cols .form-control {
  background: #f9f2fa !important;
  border: 0.271042px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 6.77606px !important;
}

.template_cols .input-group-text {
  background: #f9f2fa !important;
  border: 0.271042px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 6.77606px !important;
}

.template_cols .form-control::placeholder {
  color: #181818;
}

.template_cols .head_top a {
  background: #f9f2fa !important;
  border: 0.271042px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 6.77606px !important;
}

.template_cols .head_top.search_field {
  margin-right: 10px;
}

.template_cols .head_top .dropdown-item {
  background: #fff !important;
  border: none !important;
}

.template_cols .head_top .dropdown-menu {
  background: #fff !important;
}

.template_cols .add_folder {
  background: #572f5b;
  border-radius: 6.77606px;
  border: none;
  text-decoration: none;
  padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
}

.template_cols .add_folder img {
  margin-right: 10px;
}

.select_folder_col {
  width: 60%;
}

.select_folder {
  border-top: 0.406564px solid rgba(0, 0, 0, 0.11);
  border-bottom: 0.406564px solid rgba(0, 0, 0, 0.11);
  margin-top: 10px;
  padding: 7px 0px 10px;
}

.select_folder_col_1 {
  width: 40%;
  align-items: end;
  display: flex;
  justify-content: end;
}

.select_folder_col_1 img {
  margin-left: 10px;
}

.select_folder span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 9px;
  line-height: 21px;
  color: #181818;
}

.template_tags {
  border-radius: 6.65158px;
  width: 100%;
  margin-left: 7px;
}

.template_col {
  margin-top: 10px;
}

.template_tags h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 13.3032px;
  line-height: 20px;
  padding: 10px;
  text-transform: capitalize;
  color: #000000;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: break-all;
  background-color: #F9F2FA;
  border-radius: 8px;
}

.delete_modal h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.delete_modal p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 80%;
  margin: 0 auto;
  color: #181818;
}

.delete_modal .cancel_btn {
  background: #d9d9d9;
  color: #181818;
}

.delete_modal .btn {
  border-radius: 8.5696px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 15px;
  margin-right: 10px;
}

.delete_modal .delete_btn {
  background: #572f5b;
  color: #ffffff;
}

.delete_modal .action_btn {
  margin-top: 23px;
  margin-bottom: 20px;
}

a.add_tag.button_global {
  background: linear-gradient(109.47deg,
      #572f5b 13.06%,
      #af5e9d 67.2%,
      #572f5b 101.91%) !important;
  border: 1px solid rgba(87, 47, 91, 0.47);
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
  border-radius: 5px;
  font-style: normal;
  text-decoration: none;
  padding: 10px 13px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.redBordervalidationerror {
  border: 1px solid red;
}

.dropdown_tag {
  background: #ffffff;
  border: 1px solid #f5ebf5 !important;
  border-radius: 5px;
  display: block;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  text-decoration: none;
  color: #181818;
}

.dropdown_arrow_toggle::after {
  display: none !important;
}

.dropdown_arrow_toggle {
  background: none !important;
  border: none;
}

.textareaclass {
  border: 2px solid #673767;
  border-radius: 9px;
  width: 260px;
  margin: 0 auto;
  height: 115px;
}

.updatmodaltag {
  padding: 15px;
  /* height: 50px; */
  margin: 10px 0px;
  border-radius: 10px;
  display: flex;
}

.updatmodaltag input {
  height: 22px;
  width: 50px;
}

.dropdown_tag_new {
  background: #f9f2fa !important;
  border: 1px solid #f5ebf5 !important;
  border-radius: 5px;
  display: block;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  text-decoration: none;
  color: #181818;
}

a.new_add_button {
  background: #572f5b;
  padding: 7px;
  border-radius: 9px;
  position: absolute;
  bottom: 4%;
  right: 2%;
}

.rdw-editor-toolbar {
  background: inherit;
  border: inherit;
}

.rdw-option-wrapper {
  border: inherit;
  background: inherit;
}

.left_border_column {
  border-left: 1px solid #d9d6da;
  min-height: 230px;
}

h3.template_button_active {
  background: #AF5E9D;
  border-radius: 8px;
  color: #ffffff;
}

.success_message {
  color: #66b718 !important;
}

.not_success_message {
  color: #fa5f1c !important;
}

.react-dropdown-select-dropdown {
  width: 180px !important
}

span.react-dropdown-select-item {
  font-size: 12px;
}

.react-dropdown-select-content {
  font-size: 12px;
}

input.react-dropdown-select-input {
  font-size: 12px;
}

.dtqzBx div:first-child {
  white-space: nowrap;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  word-break: break-all;
  white-space: break-spaces !important;
}

.custom-file-upload {
  border-radius: 50%;
  display: flex;
  position: relative;
  padding: 6px;
  cursor: pointer;
  margin-bottom: 25px;
}

#photo-upload {
  display: none;
}

.upload-image-preview {
  width: 200px;
  border-radius: 50%;
  height: 200px;
}

.team_Profile_cols.active {
  background: #713777;

}

.h4_change.active {
  color: #ffffff;
}

.col-md-4.template_col_sec {
  background: #ffffff;
}

span.action_icons.last {
  margin-right: 0px !important;
  padding: 2px 14px;
  border-bottom-right-radius: 5px;
}

span.action_icons.first {
  border-bottom-left-radius: 5px;
}

/**********table***********/
.right_side .WQNxq {

  background: #FFFFFF;
  border: 1px solid #C8C8C8;
  border-radius: 10px;
  padding: 12px;
}

.right_side .sc-dkrFOg.iSAVrt.rdt_TableHeadRow {
  background: #F2F2F2;
  border-radius: 10px;
  border-bottom: none;
}

.cxcOiE:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent !important;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  border-bottom-color: #FFFFFF;
  outline-style: solid;
  outline-width: 1px;
  outline-color: none;
  border-bottom: none;
}

.rdt_TableBody .rdt_TableCell {
  border-bottom: 1px solid #d3d2d2;
}

.rdt_TableRow:hover {
  border: none;
}

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 105px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* .target_friend_request {
  background-color: #572F5B;
  margin: 8px;
  border: none;
} */
.target_friend_request {
  background-color: rgba(175, 94, 157, 0.14);
  margin: 8px;
  border: none;
  color: #181818;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  padding: 12px 45px;
}

.target_friend_requests .dropdown-menu.show {
  display: block;
  background: #FAFAFA;
  border-radius: 5px;
  border: none;
  width: 220px;
}

.target_friend_requests .dropdown-item {
  padding: 18px 0px 5px 0px;
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #6C757D;
}

.target_friend_requests .dropdown-item img {
  width: 63px;
  height: 60px;
  border-radius: 50%;
}

.target_friend_requests .dropdown-item:last-child {
  border-bottom: none;
}

.target_friend_request:hover {
  background-color: #572F5B;
  color: #ffffff;
}

/* .dropdownItem {
  transform: translate3d(-6px, 46.4px, 0px);
} */



/* Change the background color of the dropdown button when the dropdown content is shown */


/****************login pages************/
.login_pages {
  background: #F9F2FA;
  padding-bottom: 80px;
}

.login_box {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 15px 25px;
  margin-top: 20px;
}

.login_box h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #572F5B;
}

.login_pages h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  text-align: center;
  color: #747B84;
}

.login_pages h4 a {
  color: #AF5E9D;
  text-decoration: none
}

.login_box p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: rgba(65, 79, 104, 0.68);
}

.login_box .form-control {
  background: #FFFFFF;
  border: 1px solid #F2EEEE;
  border-radius: 5px;
  font-style: normal;
  border-left: none;
  font-weight: 400;
  padding-left: 0;
  height: 40px;
  font-size: 12px;
  line-height: 15px;
}

.login_box .form-control::placeholder {
  color: rgba(65, 79, 104, 0.68);
}

.login_box .input-group-text {
  background: #FFFFFF;
  border: 1px solid #F2EEEE;
  border-radius: 5px;
}

.login_box .btn {
  background: #572F5B;
  border: 1px solid #462649;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #FFFFFF;
}

.login_pages .links a {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0px;
  text-decoration: none;
  text-align: center;
  color: #AF5E9D;
  border-right: 1px solid #AF5E9D;
  padding-right: 10px;
  padding-left: 13px;
}

.login_pages .links {
  margin-top: 20px;
}

.support {
  border-right: none !important;
}

.swal2-icon.swal2-warning {
  border-color: #572F5B;
  color: white;
  background-color: #572F5B;
}



.swal2-styled.swal2-cancel {
  color: black;
}

.fa {
  color: #572f5b;
}

.tag_col_sec .react-colorful {
  position: absolute !important;
  z-index: 999;
}

.whiteiconcolor {
  color: white;
}

.tag_search_button {
  border: none;
  background-color: white;
  border: 1px solid #f5ebf5;
  border-left: none;
}

.sc-gswNZR beUiVL rdt_TableHead {
  margin-bottom: 25px;
}